export default class URLEncodedFormData extends String {
  constructor(formElement) {
    super();

    if (!(formElement instanceof HTMLFormElement)) {
      throw new Error(`URNEncodedFormData requires a form, got ${typeof form}`);
    }

    this.formElement = formElement;
  }

  toString() {
    const urlEncodedDataPairs = [];
    const inputs = this.formElement.getElementsByTagName('input');

    // Turn the data object into an array of URL-encoded key/value pairs.
    // for-loop used on purpose to loop over HTMLCollection
    for (let index = 0, len = inputs.length; index < len; index++) {
      const { name, value } = inputs[index];
      urlEncodedDataPairs.push(
        `${encodeURIComponent(name)}=${encodeURIComponent(value)}`,
      );
    }

    // Combine the pairs into a single string and replace all %-encoded spaces to
    // the '+' character; matches the behaviour of browser form submissions.
    return urlEncodedDataPairs.join('&').replace(/%20/g, '+');
  }
}
